import "./stylesheets/style.scss";

const themes = [
    {
        className: 'triad-basic',
        name: '3色',
        colors: ['#C0E4F2', '#FFF7C9', '#F7C4D4'],
    },
    {
        className: 'triad-basic-frame',
        name: '3色白枠',
        colors: ['#C0E4F2', '#FFF7C9', '#fc8fb3'],
    },
    {
        className: 'triad-2',
        name: '3色グラデーション',
        colors: ['#C0E4F2', '#FFF7C9', '#fc8fb3'],
    },
    {
        className: 'triad-min',
        name: 'バイトーン',
        colors: ['#6dd1f8', '#fc8fb3'],
    },
    {
        className: 'split-comp',
        name: 'スプリット・コンプリメンタリ',
        colors: ['#cbffc9', '#FD95C1', '#6dd1f8'],
    },
];

const initSp = () => {
  const closeButton = document.querySelector('.close-button');
  closeButton.addEventListener('click', () => {
      closeButton.closest('header').classList.remove('open');
  });
  const hamburger = document.getElementById('sp-menu-hamburger');
  hamburger.addEventListener('click', () => {
      hamburger.closest('header').classList.add('open');
  });
}

const init = () => {
    const input = document.getElementById('theme-changer-input');
    const menu = document.querySelector('.theme-menu');
    themes.forEach(theme => {
        const li = document.createElement('li');
        theme.colors.forEach(c => {
            const span = document.createElement('span');
            span.style.backgroundColor = c;
            li.appendChild(span);
        });
        li.appendChild(document.createTextNode(theme.name));
        li.addEventListener('click', () => {
            input.checked = !input.checked;
            document.body.classList.forEach(c => {
                document.body.classList.remove(c);
            });
            document.body.classList.add(theme.className);
        });

        menu.appendChild(li);
    });

    initSp();
}

init();